import { render, staticRenderFns } from "./About.vue?vue&type=template&id=e9b95498&scoped=true"
import script from "./About.vue?vue&type=script&lang=js"
export * from "./About.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9b95498",
  null
  
)

export default component.exports